/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* 구글 폰트 임포트 */
.jua-regular {
}

body {
  @apply jua-regular;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Jua", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  @apply max-w-4xl relative m-auto;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(256, 256, 256, 0.2); /* 반투명 배경 */
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%; /* 원형 버튼 */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px !important;
  color: #ffffff90 !important; /* 화살표 색상 */
}

/* animation slide */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide-container {
  overflow: hidden;
  width: 100%;
}
.slide-track {
  display: flex;
  width: 100%;
  animation: slide 30s linear infinite;
}
.slide-item {
  flex: 0 0 16.666%;
  box-sizing: border-box;
}
